<i18n>
ru:
  measure: '({measure})'
  modifiersTitle: Дополнительные ингредиенты
ua:
  measure: '({measure})'
  modifiersTitle: Додаткові інгредієнти
us:
  measure: '({measure})'
  modifiersTitle: Additional ingredients
</i18n>

<template>
  <div
    v-if="product"
    class="v-product"
    :class="[
      fromPopup ? `v-product__${appConfig.VueSettingsPreRun.ProductLayout}` : 'v-product__KhazadDum'
    ]"
  >
    <div class="v-left-part">
      <div
        itemscope
        :class="[
          product.ModifierGroups.length > 0
            ? 'v-menu-item-image v-menu-item-image__has_modifiers'
            : 'v-menu-item-image'
        ]"
        itemtype="http://schema.org/ImageObject"
      >
        <common-product-image-zoomable
          :alt="product.Name"
          :image="product.NormalImage"
        />
      </div>
    </div>
    <div class="v-right-part">
      <div class="v-product-page-summary v-mb-xs v-scrollbar">
        <div class="v-product-page-summary-top v-mb-xs">
          <h1
            class="v-product-card-title v-mb-xs"
            :class="{ 'v-d-inline-block v-mr-sm': isExtraSmall }"
            itemprop="name"
            v-html="sanitize(product.Name)"
          />

          <div
            v-if="caloricValue && (caloricValue.Quantity > 0 || caloricValue.Weight > 0)"
            class="v-product-card-weight v-mb-xs"
          >
            <i18n-t
              keypath="basePage.measure"
              scope="global"
              tag="span"
            >
              <template #measure>
                <common-quantity-measure
                  v-if="caloricValue.Quantity > 0"
                  :measure="caloricValue.Measure"
                  :quantity="caloricValue.Quantity"
                />
                <common-quantity-measure
                  v-else
                  :quantity="caloricValue.Weight"
                />
              </template>
            </i18n-t>
          </div>

          <menu-description
            v-if="!(isExtraSmall && product.DescriptionType === ProductDescriptionType.Ingredients)"
            is-popup-or-full-page
            :product-in-list="product"
          />

          <template v-if="caloricValue && hasCaloric(caloricValue)">
            <menu-caloric
              hide-weight
              :caloric-product="caloricValue"
              :in-one-line="!!isExtraSmall"
            />
          </template>

          <menu-description
            v-if="isExtraSmall && product.DescriptionType === ProductDescriptionType.Ingredients"
            is-popup-or-full-page
            :product-in-list="product"
          />

          <div
            v-if="!stringIsNullOrWhitespace(product.PersonalProductMessage)"
            class="v-product-card-personal-product-message v-xs-small v-error-color"
          >
            <icon-menu-info /> <span v-html="sanitize(product.PersonalProductMessage)" />
          </div>

          <div
            v-if="!stringIsNullOrWhitespace(product.NoOrderTypeReasonText)"
            class="v-product-card-no-pickup-or-delivery-reason-text v-xs-small v-error-color"
          >
            <icon-menu-info /> <span v-html="sanitize(product.NoOrderTypeReasonText)" />
          </div>

          <div
            class="v-option"
            data-test-id="product-option"
          >
            <menu-option-selector
              :options="product.Options"
              :product="product"
              :threshold="appConfig.VueSettingsPreRun.ProductOptionThreshold"
              data-test-id="product-page-options"
            />
          </div>
          <menu-products-containing-product
            :product-id="productId"
            :product-name="product.Name"
          />
        </div>
        <div
          v-if="product.ModifierGroups.length > 0"
          class="v-product-page-summary--scroll-part"
        >
          <div class="v-product-page-summary-center">
            <h3
              class="v-product-page-summary-center__modifiers-title"
              :class="[product.MinCountModifiers === 0 ? 'v-mb-xs' : 'v-mb-md']"
              v-html="translate('basePage.modifiersTitle')"
            />
            <menu-product-base-modifiers
              :max-modifiers-count="product.MaxCountModifiers"
              :min-modifiers-count="product.MinCountModifiers"
              :modifiers-groups="product.ModifierGroups"
              :product-id="productId"
            />
          </div>
        </div>
      </div>

      <div class="v-product-page-summary-bottom">
        <div
          v-if="isProductUnavailable(product)"
          class="v-flex-100 v-d-flex v-flex-row"
        >
          <div
            v-if="menuStore.ProductsInStopList.includes(product.ID)"
            class="v-nomenclature-card-in-stop-list v-product-in-stop-list v-btn-lg"
            data-test-id="product-in-stop-list"
          >
            <span v-html="translate('productCard.inStopListButton')" />
          </div>
          <common-popover
            v-else
            class="v-w-100"
          >
            <template #hoverable>
              <div
                class="v-nomenclature-card-in-stop-list v-product-in-stop-list"
                data-test-id="slot-half-card-unavailable-by-order-type"
              >
                <span v-html="translate('productCard.notAvailableByOrderTypeButton')" />
              </div>
            </template>
            <template #content>
              <span
                v-html="
                  clientStore.orderTypeNotSelected
                    ? translate('productCard.notAvailableByOrderType')
                    : translate('productCard.notAvailableByOrderTypeTooltip', {
                        orderType: translate(clientStore.orderTypeText)
                      })
                "
              />
            </template>
          </common-popover>
        </div>

        <template v-else>
          <div
            v-if="
              appConfig.VueSettingsPreRun.MenuItemIngredientsShowSavings &&
              product.DescriptionType === ProductDescriptionType.Ingredients &&
              comboPriceSeparately > price
            "
            class="v-sets-price-with-savings v-mb-sm"
          >
            <div class="v-d-flex v-justify-content-between v-body-text-color-light v-mb-xs">
              <span v-html="translate('productCard.priceWithoutCombo')" />
              <common-currency :amount="comboPriceSeparately" />
            </div>
            <div class="v-d-flex v-justify-content-between">
              <span
                class="v-font-weight-600"
                v-html="translate('productCard.comboProductSaving')"
              />
              <common-currency
                class="v-success-color"
                :amount="comboPriceSeparately - price"
              />
            </div>
          </div>
          <menu-product-add-to-cart-button-page
            :button-click="buttonClick"
            :disabled="menuStore.disabledButtonIfRequiredModsNotSelected"
            :price="price"
            :price-in-points="priceInPoints"
            :product-in-list="product"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { FirstAddType, Guid, ProductDescriptionType } from '~api/consts'

import type { HaveCaloricValues, Measurable } from '~types/common'
import type { Product } from '~types/menuStore'

import { type GUID, useCommon, useWindowSize } from '@arora/common'

const { productId, isRecommendation } = defineProps<{
  productId: GUID
  isRecommendation?: boolean
}>()

const appConfig = useAppConfig()

const clientStore = useClientStore()
const menuStore = useMenuStore()
const popupStore = usePopupStore()

const { fromPopup } = useInstance()
const { stringIsNullOrWhitespace, objectKeys } = useCommon()
const { addToCart } = useCartFunctionsWithDialogs()
const { sanitize, translate } = useI18nSanitized()
const {
  getIngredientOptionIfExist,
  getSameProductsInCart,
  hasCaloric,
  isProductUnavailable,
  makeDefaultOption,
  setProductModifiersToZero,
  viewProduct
} = useProduct()
const { isExtraSmall } = useWindowSize()

const product = ref<Product | null | undefined>(
  appConfig.Products.find((product) => product.ID === productId)
)
const fromPopupLocal = fromPopup.value

onMounted(async () => {
  const productInCart = getSameProductsInCart(productId)[0]

  let inStoreMap = menuStore.SelectedModifiersPerProduct.get(productId)

  if (!inStoreMap) {
    menuStore.SelectedModifiersPerProduct.set(productId, {})
    inStoreMap = {}
  }

  if (productInCart) {
    for (const mod of productInCart.Modifiers) {
      inStoreMap[mod.ID] = mod.Count
    }

    setProductModifiersToZero(
      productId,
      productInCart.Modifiers.map((mod) => mod.ID)
    )
  } else {
    const removedIngredients: GUID[] =
      product.value && product.value.RemovableIngredients.length > 0
        ? (product.value.RemovableIngredients.filter(
            (ing) => ing.ModifierId && inStoreMap && inStoreMap[ing.ModifierId]
          ).map((ing) => ing.ModifierId) as GUID[])
        : []

    setProductModifiersToZero(productId, removedIngredients)
  }

  if (product.value) {
    makeDefaultOption(product.value)
    calculatePrice()
    viewProduct(product.value, product.value.Price)
  }
})

async function buttonClick(productId: GUID): Promise<void> {
  const added = await addToCart(
    productId,
    isRecommendation ? FirstAddType.Recommendation : FirstAddType.Default,
    product.value!.GroupID,
    product.value!.Name,
    product.value!.Price,
    menuStore.SelectedOptionsPerProduct.get(productId)?.ID,
    menuStore.SelectedModifiersPerProduct.get(productId),
    1
  )
  if (added && fromPopupLocal) {
    popupStore.closePopup()
  }
}

const price = ref<number>(0)
const priceInPoints = ref<number>(0)

function calculatePrice(): void {
  if (!product.value) return

  let result =
    product.value.PriceModified + (menuStore.SelectedOptionsPerProduct.get(product.value.ID)?.Price ?? 0)
  priceInPoints.value =
    product.value.PriceInPoints +
    (menuStore.SelectedOptionsPerProduct.get(product.value.ID)?.PriceInPoints ?? 0)

  if (menuStore.SelectedModifiersPerProduct.get(productId)) {
    const modifiers = menuStore.SelectedModifiersPerProduct.get(productId)

    if (modifiers)
      for (const key of objectKeys(modifiers)) {
        const value = modifiers[key]
        for (const modifiersGroup of product.value.ModifierGroups) {
          for (const modifier of modifiersGroup.Modifiers) {
            if (modifier.ID === key) {
              result += modifier.PriceModified * value
            }
          }
        }
      }
  }

  price.value = result
}

const caloricValue = computed<(HaveCaloricValues & Measurable) | undefined>(() => {
  if (!product.value) return undefined

  if (
    product.value.UseOptionsDescription &&
    menuStore.SelectedOptionsPerProduct.get(product.value.ID) &&
    hasCaloric(menuStore.SelectedOptionsPerProduct.get(product.value.ID))
  )
    return menuStore.SelectedOptionsPerProduct.get(product.value.ID)

  return product.value
})

const comboPriceSeparately = computed<number>(() => {
  let separatelyPrice = 0

  for (const ingredient of product.value?.IngredientsSet ?? []) {
    const ingredientWithOptionIfExist = getIngredientOptionIfExist(product.value as Product, ingredient)
    const ingredientPriceWithOptionIfExist =
      ingredient.PriceModified +
      (ingredient.ID === ingredientWithOptionIfExist.ID ? 0 : ingredientWithOptionIfExist.Price)

    separatelyPrice += ingredientPriceWithOptionIfExist
  }

  return separatelyPrice
})

watch(
  () => menuStore.SelectedModifiersPerProduct.get(productId),
  () => {
    calculatePrice()
  },
  { deep: true }
)

watch(
  () => menuStore.SelectedOptionsPerProduct.get(product.value?.ID ?? Guid.Empty)?.ID,
  (value, oldValue) => {
    if (value !== oldValue && oldValue !== undefined) {
      calculatePrice()
    }
  }
)
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-product__KhazadDum {
  margin-right: -10px;
  margin-left: -10px;

  .v-left-part {
    flex: 0 0 60%;
    max-width: 60%;

    @include mixins.sm {
      padding-right: 0;
    }

    .v-menu-item-image {
      .v-img-fluid {
        border-radius: min(variables.$BorderRadius, 6px);
        overflow: hidden;
      }
    }
  }

  .v-right-part {
    flex: 0 0 40%;
    max-width: 40%;
    padding-left: 10px;
  }
}

.v-product__Moria {
  .v-left-part {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .v-right-part {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .v-product-page-summary-bottom {
    display: flex;
    justify-content: flex-end;
    .v-btn {
      width: fit-content;
    }
  }
}

.v-product__Mazarbul {
  .v-left-part {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 16px;
    padding-right: 16px;

    .v-menu-item-image {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;

      border-radius: variables.$BorderRadius min(variables.$BorderRadius, 6px)
        min(variables.$BorderRadius, 6px) variables.$BorderRadius;
      overflow: hidden;

      &__has_modifiers {
        .v-img-fluid {
          border-radius: min(variables.$BorderRadius, 6px);
          overflow: hidden;
          object-fit: contain;
          object-position: left top;
          width: auto;
        }

        @include mixins.sm {
          width: 100%;
          height: auto;
        }
      }

      @include mixins.sm {
        position: inherit;
        top: 0;
        border-radius: min(variables.$BorderRadius, 6px) variables.$BorderRadius variables.$BorderRadius
          min(variables.$BorderRadius, 6px);
      }

      .v-img-fluid {
        height: 100%;
        border-radius: min(variables.$BorderRadius, 6px);
        overflow: hidden;
        object-fit: cover;
        width: auto;

        @include mixins.sm {
          width: 100%;
          height: auto;
        }
      }
    }

    @include mixins.sm {
      padding: 0;
    }
  }

  .v-right-part {
    flex: 0 0 50%;
    max-width: 50%;
    padding: variables.$PopupPadding variables.$PopupPadding variables.$PopupPadding 16px;

    @include mixins.sm {
      padding: 16px;
    }
  }
}

.v-product {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .v-left-part {
    position: relative;
    @include mixins.sm {
      flex: 0 0 100%;
      max-width: 100%;
      .v-menu-item-image {
        margin-bottom: 12px;
      }
    }
  }

  .v-right-part {
    display: flex;
    flex-direction: column;

    @include mixins.sm {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .v-product-page-summary {
      position: relative;
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
      flex-grow: 1;

      &--scroll-part {
        max-height: 225px;
        scrollbar-gutter: stable;
        overflow-y: auto;
      }

      &-center__modifiers-title {
        font-size: 1.15rem;
        font-weight: 600;
        color: variables.$PrimaryBackgroundColor;
      }
      .v-product-card-title {
        font-weight: bold;

        @include mixins.sm {
          font-size: 1.5rem;
        }
      }

      .v-product-card-weight {
        font-weight: 400;
        font-size: calc(variables.$TextSizeMain * 1.4);

        @include mixins.sm {
          font-size: 1.15rem;
        }
      }
    }

    .v-caloric-column {
      width: auto;
      flex: 0 0 auto;
      max-width: none;
    }
  }
}

.v-sets-price-with-savings {
  font-size: 0.85rem;
  margin: 0 0.5rem;

  @include mixins.sm {
    margin: 0;
    font-size: 0.85rem;
  }
}

.v-product-card-personal-product-message,
.v-product-card-no-pickup-or-delivery-reason-text {
  svg {
    width: 1.1rem;
    height: 1.1rem;
  }
}
</style>
